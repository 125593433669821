<template>
    <table class="table">
        <thead>
            <tr>
                <th style="width: 15%">
                    {{ activeLanguage.date }}
                    <i
                        aria-hidden="true"
                    ></i>
                </th>
                <th style="width: 20%">
                    {{ activeLanguage.invoice_number }}
                    <i
                        aria-hidden="true"
                    ></i>
                </th>
              <th style="width: 20%">
                {{ activeLanguage.email }}
                <i
                    aria-hidden="true"
                ></i>
              </th>
                <th style="width: 10%">
                    {{ activeLanguage.rating }}
                    <i
                        aria-hidden="true"
                    ></i>
                </th>
                <th style="width: 35%">
                  {{ activeLanguage.message }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="item in data">
               <td>{{ formatDate(item.created_at) }}</td>
               <td>{{ item.invoice_number }}</td>
               <td>{{ item.email }}</td>
               <td>{{ item.rate }}</td>
               <td>{{ item.message }}</td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import moment from 'moment'

export default {
    name: 'nps-table',
    props: {
        activeLanguage: {
            type: Object,
            default: () => { return {}}
        },
        data: {
            type: Array,
            default: () => { return [] }
        },
        sorting: {},
    },
    data() {
      return {

      }
    },
    methods: {
      formatDate(date) {
        return moment(date).format('DD-MM-YYYY')
      }
    }
}
</script>