<template>
    <div class="main">
        <div class="container">
            <div class="title">
                <h1>{{ activeLanguage.title }}</h1>
            </div>
            <div class="filter-bar-container">
                <div class="filter-bar">
                    <div>
                        <button type="button" class="btn btn-secondary" @click="toggleFilter">
                            <i class="filter-icon fa fa-filter fa-lg" aria-hidden="true"></i>
                            {{ activeLanguage.filterBtn }}
                            <i class="fa fa-caret-down caret" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
                <transition name="collapse">
                    <nps-filter
                        @clearNum="delete activeFilters.invoice"
                        @onFiltersApply="onFiltersApply"
                        ref="npsFilter"
                        :activeLanguage="activeLanguage.filterTable"
                        :startFromDate="manageOrderRight ? activeFilters.startFromDate : null"
                        :manageOrderRight="manageOrderRight"
                    />
                </transition>
            </div>
            <nps-table :activeLanguage="activeLanguage.npsTable"
                       :data="data"
                       @onSortChange="onSortChange" />
            <div class="col-12">
              <div style="height: 35px"></div>
              <b-pagination
                  align="center"
                  :prev-text="paginationLanguage.pagPrev"
                  :next-text="paginationLanguage.pagNext"
                  @input="getAllFeedback(pagData.currentPage)"
                  :total-rows="pagData.maxItems"
                  v-model="pagData.currentPage"
                  :per-page="20"
                  v-if="pagData.maxItems > 20"
                  :disabled="false"
              />
              <PaginationCount
                  :pag-data="pagData"
                  :activeLanguage="paginationLanguage.pagTxt"
              />
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from  'vuex'
import NpsFilter from '../../components/npsView/NpsFilter.vue'
import NpsTable from '../../components/npsView/NpsTable.vue'
import Pagination from '../../components/common/reusable/Pagination.vue'
import PaginationCount from "../../components/common/reusable/PaginationCount";

export default {
    data() {
        return {
            data: [],
            activeFilters: {},
            sorting: {
                sort: 'order_date',
	            sort_dir: 'desc',
            },
			pagData: {
				currentPage: 1,
				maxItems: 0,
            },
            show_filter: true
        }
    },
    mounted() {
        this.getAllFeedback()
    },
    methods: {
        getAllFeedback(page = 1, perPage = 20) {
          let params = {
            filter: this.activeFilters,
            sort: this.sorting.sort,
            page,
            perPage
          }
          newbillinkAxios
              .post('/feedback', params)
              .then(({ data }) => {
                this.data = data.item_list;
                this.pagData.maxItems = data.total_items;
              })
              .catch(err => console.error(err))
        },
      toggleFilter(){
        this.show_filter = !this.show_filter;
      },
        onSortChange(type) {
            if (this.sorting.sort === type) {
				this.sorting.sort_dir = this.sorting.sort_dir === 'asc' ? 'desc' : 'asc'
            } else {
				this.sorting.sort_dir = 'desc'
            }
			this.sorting.sort = type

			this.getAllFeedback()
        },
        onFiltersApply(data) {
		    this.activeFilters = data
		    this.getAllFeedback()
	    },
    },
    components: {
        NpsFilter,
        NpsTable,
        Pagination,
        PaginationCount
    },
    computed: {
			...mapGetters({
				activeLanguage: 'getNPSLanguage',
        paginationLanguage: 'getOrdersLanguage',
				manageOrderRight: 'userManage_orders',
                userView_parent_orders: 'userView_parent_orders',
                isAdmin: 'isAdmin'
            })
		},
}
</script>