<template>
    <div class="show collapse" id="collapseExample">
        <div class="filter-content">
            <form @submit.prevent="onNpsFilterSubmit">
                <div class="form-row">
                    <div class="form-group col-lg-12">

                        <div id="event_period">

                            <div class="form-row">
                                <div class="form-group col-sm-6 col-md-3">
                                    <label class="col-form-label" for="created_at">{{ activeLanguage.from }}</label>
                                    <div class="input-group">
                                        <Datepicker
                                            v-model="created_at"
                                            :calendar-button="true"
                                            calendar-button-icon="fa fa-calendar"
                                            input-class="form-control actual_range"
                                            :placeholder="activeLanguage.from"
                                            format="yyyy-MM-dd"
                                            :language="langActive"
                                            :clear-button="true"
                                        />
                                    </div>
                                </div>

                                <div class="form-group col-md-3">
                                    <label for="input6" class="col-form-label">{{ activeLanguage.inv }}</label>
                                    <input
                                        class="form-control mr-sm-2"
                                        type="text"
                                        aria-label="Invoice number"
                                        v-model="form.invoice_number"
                                    >
                                </div>

                              <div class="form-group col-md-3">
                                <label for="input2" class="col-form-label">{{ activeLanguage.email }}</label>
                                <input
                                    class="form-control mr-sm-2"
                                    type="email"
                                    aria-label="Invoice number"
                                    v-model="form.email"
                                >
                              </div>

                              <div class="form-group col-md-3">
                                <label for="input1" class="col-form-label">Rating</label>
                                <select class="custom-select" v-model="form.rate">
                                  <option selected="selected">10</option>
                                  <option>9</option>
                                  <option>8</option>
                                  <option>7</option>
                                  <option>6</option>
                                  <option>5</option>
                                  <option>4</option>
                                  <option>3</option>
                                  <option>2</option>
                                  <option>1</option>
                                </select>
                              </div>
                                <div class="form-group col-12 filter-btn-pos">
                                    <button type="submit" class="btn mr-1">{{ activeLanguage.applyBtn }}</button>
                                    <button type="button" @click="clearInvoiceFilter" class="btn btn-secondary">{{ activeLanguage.clearBtn }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

        </div>
    </div>
</template>

<script>
	import Datepicker from 'vuejs-datepicker';
	import moment from 'moment';
	import {mapGetters} from  "vuex";

	import ClientItemTemplate from '../common/reusable/ClientItemTemplate.vue'
    import { en, nl } from 'vuejs-datepicker/dist/locale'
    import Helpers from '../../helpers/modules/scripts'
    
	export default {
		name: "nps-filer",
		props: {
			activeLanguage: {
				type: Object,
				required: true
			},
		},
        data() {
			return {
				form: {},
				created_at: null
            }
        },
		components: {
			Datepicker
        },
        // mounted() {
        //     this.nl.months = Helpers.uppercaseArr(this.nl.months)
        //     this.nl.monthsAbbr = Helpers.uppercaseArr(this.nl.monthsAbbr)
        //     this.nl.days = Helpers.uppercaseArr(this.nl.days)
        // },
		// computed: {
		// 	...mapGetters({
		// 		manageOrdersPermission: 'userManage_orders',
		// 		isSuperUser: 'isSuperUser',
		// 		computedConnectedUsers: 'getComputedConnectedUsers',
    //             btoken: 'getBillinkToken',
    //             getActiveLanguageName: 'getActiveLanguageName'
    //         }),
    //         langActive () {
    //             return this.getActiveLanguageName === 'english' ? this.en : this.nl
    //         }
		// },
        methods: {
	        onNpsFilterSubmit() {
		        if (this.created_at) { this.form.created_at = moment(this.created_at).format('YYYY-MM-DD') }
	        	this.$emit('onFiltersApply', this.form)
            },
	        clearInvoiceFilter() {
		        this.form = {}
		        this.created_at = null
		        this.$emit('clearNum')
		        this.$emit('onFiltersApply', {})
            }
        }
	}
</script>

<style scoped lang="scss">
    input[type="email"]:focus {
      border: 1px solid #f0843a !important;
      -webkit-box-shadow: 0 0 0 0.2rem rgba(240, 132, 58, 0.25) !important;
      box-shadow: 0 0 0 0.2rem rgba(240, 132, 58, 0.25) !important;
    }
    .vdp-datepicker {
      z-index: 1000;
    }
    .mg-l-50 {
      margin-left: 50px;
    }
    .padd-l-7 { padding-left: 7px !important; }
    .padd-r-11 { padding-right: 11px !important; }
    .padd-r-14 { padding-right: 14px !important; }
    .padd-l-8 { padding-left: 8px !important; }
    .payout-container {
        max-width: 100%;
    }
    @media (max-width: 992px) {
        .payout-container {
            margin: 0 auto;
            float: none;
        }
    }
    @media (max-width:768px) {
        .filter-btn-pos {
            display: flex;
            justify-content: space-between;
        }
    }
</style>